import React from 'react';
import { MastermindPlusProps } from '../../mastermind-texts';
import MarkdownRendererGlobal from '../../components/MarkdownRendererGlobal';
import MastermindTitle from '../../components/MastermindTitle';

type Props = {};

export default function KalendarzZjazdow({}: Props) {
	return (
		<div className="flex flex-col items-center gap-10">
			<MastermindTitle>Kalendarz zjazdów Mastermind+</MastermindTitle>
			<div className="flex flex-wrap items-stretch justify-center zjazd-tiles-holder">
				{MastermindPlusProps.KalendarzZjazdow.map((x: string, index: number) => {
					return (
						<div key={index} className="rounded-xl flex flex-col px-5 pt-8 gap-3 pb-20 zjazd-tile w-[322px]">
							<MarkdownRendererGlobal text={x} pClasses="text-sm font-light tracking-[2px]" liClasses="text-sm font-light tracking-[2px]" />
						</div>
					);
				})}
			</div>
		</div>
	);
}
