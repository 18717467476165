import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MastermindSidebarTitle, mastermindTitles } from '../../mastermind-texts';
import { BsChevronCompactDown } from 'react-icons/bs';

type Props = {
	insightsClicked: boolean | null;
};

export default function MastermindFooter({ insightsClicked }: Props) {
	const location = useLocation();

	function handleCurrentPageClick() {
		document.querySelector('.footer--expander')?.classList.toggle('active');
		document.body.style.position = 'fixed';
	}

	function handleCloseFooter() {
		document.querySelector('.footer--expander')?.classList.remove('active');
	}

	return insightsClicked ? (
		<>
			<footer className="w-full text-black site--footer flex justify-center items-center mastermind-footer">
				<BsChevronCompactDown className="absolute left-6" size={30} onClick={handleCurrentPageClick} />
				<span className="uppercase font-semibold text-xs tracking-[2.5px] text-center px-5 max-w-[300px]" onClick={handleCurrentPageClick}>
					{mastermindTitles.find((x) => x.link === location.pathname)?.value || ''}
				</span>
			</footer>
			<div className="footer--expander flex flex-col mastermind-expander">
				<ul className="flex gap-20 dotted-list in-place items-center tracking-[3px] mt-10">
					{mastermindTitles.map((title: MastermindSidebarTitle, index: number) => {
						return (
							<li
								key={index}
								className={`${title.link === location.pathname ? 'active' : ''}`}
								onClick={() => {
									handleCloseFooter();
								}}>
								<Link to={title.link}>
									<div>{title.value}</div>
								</Link>
							</li>
						);
					})}
					<li>
						<div>
							<Link to="/">Strona główna OCEAN CAPITAL</Link>
						</div>
					</li>
				</ul>
			</div>
		</>
	) : (
		<></>
	);
}
