import React from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
	text: string;
	pClasses?: string;
	liClasses?: string;
	h2Classes?: string;
};

export default function MarkdownRendererGlobal({ text, pClasses, liClasses, h2Classes }: Props) {
	return (
		<ReactMarkdown
			className="markdown-renderer-global contents"
			components={{
				em: (props) => {
					return <em className="tracking-[2px]">{props.children}</em>;
				},
				h1: (props) => {
					return <h1 className="tracking-[2px] text-2xl font-medium">{props.children}</h1>;
				},
				h2: (props) => {
					return <h2 className={`tracking-[2px] text-xl font-medium contents ${h2Classes || ''}`}>{props.children}</h2>;
				},
				h3: (props) => {
					return <h2 className={`tracking-[2px] font-medium contents`}>{props.children}</h2>;
				},
				h5: (props) => {
					return <h5 className={`tracking-[1px] text-sm font-medium contents`}>{props.children}</h5>;
				},
				p: (props) => {
					return <p className={`tracking-[1px] font-medium ${pClasses || ''}`}>{props.children}</p>;
				},
				li: (props) => {
					return <li className={`tracking-[1px] font-medium ${liClasses || ''}`}>{props.children}</li>;
				},
				a: (props) => {
					return (
						<a href={props.href} target="_blank" className="font-medium">
							{props.children}
						</a>
					);
				},
			}}>
			{text}
		</ReactMarkdown>
	);
}
