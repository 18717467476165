import React, { useEffect, useRef, useState } from 'react';
import MastermindIcon from '../../images/icons/mastermind.svg';
import Logo from '../../images/logo_white.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import People from '../../images/mastermind_people.png';
import { mastermindTexts, mastermindTitles } from '../mastermind-texts';
import MarkdownRenderer from '../desktop_tablet/MastermindPlusPages/MarkdownRenderer';
import MastermindFooter from './Footers/MastermindFooter';

type Props = {};

export default function Mastermind({}: Props) {
	const [insightsClicked, setInsightsClicked] = useState<boolean>(false);

	const location = useLocation();
	const pathname: string = location.pathname;
	const navigate = useNavigate();

	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		document.querySelectorAll<HTMLDivElement>('.mastermind-circles .circle').forEach((circle: HTMLDivElement) => {
			if (circle.id !== 'circle-' + id) {
				circle.classList.remove('active');
			}
		});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}

	function handleInsightsClick() {
		if (!insightsClicked) {
			setTimeout(() => {
				document.querySelector('.mastermind-content-displayer')?.classList.add('active');

				navigate(`przymierze-umyslow`);
			}, 400);
		} else {
			document.querySelector('.mastermind-content-displayer')?.classList.remove('active');

			navigate(`/mastermind`);
		}
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		document.querySelector<HTMLDivElement>('.site--content')?.style.setProperty('--actualViewportHeight', `${window.innerHeight}px`);

		if (pathname.length > '/mastermind'.length) {
			document.querySelector('.mastermind-content-displayer')?.classList.add('active');

			setInsightsClicked(true);
		}
	}, []);

	useEffect(() => {
		document.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
		});
		document.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});

		document.querySelector('.mastermind-content-displayer')?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	useEffect(() => {
		// to handle changing pathname using the next/back arrows - not by clicking the logo
		if (pathname === '/mastermind') {
			setInsightsClicked(false);
		} else {
			setInsightsClicked(true);
		}

		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});
	}, [pathname]);

	return (
		<>
			<div className="mastermind-content site--content">
				<div className="mastermind-icon " onClick={handleInsightsClick}>
					<div className="relative">
						<img src={MastermindIcon} className="w-[100px]" />
						<div className="circle-small bottom-[-3px] right-[-5px] opacity-50 forth"></div>
					</div>
				</div>
				{!insightsClicked && (
					<Link to="/" className="cursor-pointer absolute z-[300] bottom-6 left-4">
						<img src={Logo} className="w-[100px]" />
					</Link>
				)}

				<div className="mastermind-circles">
					<div className="relative">
						<div className="circle" id="circle-1">
							<div>
								<i>
									„Umysł ludzki można porównać do baterii elektrycznej. Ogólnie wiadomo, że zespół baterii elektrycznych wytwarza więcej
									energii od baterii pojedynczej.
								</i>
								<span className="font-bold opacity-50">Napoleon Hill</span>
							</div>
						</div>
						<div className="circle-small first" id="1" onClick={handleDotClick}></div>
					</div>
					<div className="relative">
						<div className="circle" id="circle-2">
							<div>
								<span className="font-bold mt-6"> Tomasz Dalach</span>
								<span className="">Mentor prowadzący</span>
								<span className="opacity-60">MASTERMIND OCEAN CAPITAL </span>
								<Link to="/mentoring/tomasz-dalach" className="w-fit ml-auto">
									<i className="opacity-60 font-bold">Link</i>
								</Link>
								<span className="font-bold mt-6"> Aleksandra Ślifirska</span>
								<span className="">Mentorka wspierająca</span>
								<span className="opacity-60">MASTERMIND OCEAN CAPITAL </span>
								<Link to="/mentoring/aleksandra-slifirska" className="w-fit ml-auto">
									<i className="opacity-60 font-bold">Link</i>
								</Link>
							</div>
							<img src={People} />
						</div>
						<div className="circle-small second" id="2" onClick={handleDotClick}></div>
					</div>
					<div className="relative">
						<div className="circle" id="circle-3">
							<div>
								<i>
									„Żaden umysł nie jest kompletny sam w sobie. Każdy potrzebuje połączenia i kontaktu z innymi umysłami, aby wzrastać i
									rozszerzać swoją świadomość.”
								</i>
								<span className="font-bold opacity-50">Napoleon Hill</span>
							</div>
						</div>
						<div className="circle-small third" id="3" onClick={handleDotClick}></div>
					</div>
					<div className="relative">
						<div className="circle" id="circle-4"></div>
					</div>
				</div>
				<div className="mastermind-content-displayer text-white">
					<MarkdownRenderer text={mastermindTexts[pathname]} />
				</div>
			</div>
			<MastermindFooter insightsClicked={insightsClicked} />
		</>
	);
}
