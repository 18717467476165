import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../images/logo.png';
import { AppNavigation, NavigationItem } from '../globalTypes';

type Props = {};

export default function Header({}: Props) {
	const location = useLocation();

	const [isOpen, setIsOpen] = useState(false);

	const noHeaderRoutes: string[] = ['/mastermind', '/mastermind+'];

	useEffect(() => {
		const paths: string[] = ['/', '/mentoring', '/mastermind+', '/dla-kogo', '/na-miare', '/referencje', '/kontakt']; //these need to be in the same order as they are placed on the page (from top to bottom)

		const headerItems: NodeListOf<HTMLLIElement> = document.querySelectorAll<HTMLLIElement>('.header--list>li');

		const modalItems: NodeListOf<HTMLLIElement> = document.querySelectorAll<HTMLLIElement>('.modal--list>li');

		const pathname: string = location.pathname;

		let nested: boolean = false;
		if (pathname.slice(1).indexOf('/') !== -1) {
			nested = true;
		}

		const path: string = !nested ? pathname : '/' + pathname.slice(1).substring(0, pathname.slice(1).indexOf('/'));

		headerItems.forEach((li: HTMLLIElement) => li.classList.remove('active'));
		modalItems.forEach((li: HTMLLIElement) => li.classList.remove('active'));
		if (paths.indexOf(path) !== -1) {
			headerItems[paths.indexOf(path)].classList.add('active');
			modalItems[paths.indexOf(path)].classList.add('active');
		}

		document.querySelector('.modal')?.classList.remove('active');
		document.getElementById('overlay--main--modal')?.classList.remove('active');

		setIsOpen(false);
	}, [location]);

	useEffect(() => {
		const html = document.querySelector('html');

		if (isOpen) {
			document.querySelector('.barToggler')?.classList.add('open');
			if (html) html.style.overflowY = 'hidden';
		} else {
			document.querySelector('.barToggler')?.classList.remove('open');
			if (html) html.style.overflowY = 'visible';
		}
	}, [isOpen]);

	function handleCloseModal() {
		setIsOpen(false);
		document.querySelector('.modal')?.classList.remove('active');
		document.getElementById('overlay--main--modal')?.classList.remove('active');
		const html = document.querySelector('html');
		if (html) html.style.overflowY = 'visible';
	}

	function toggleBar() {
		setIsOpen((prev) => !prev);
		document.querySelector('.modal')?.classList.toggle('active');
		document.getElementById('overlay--main--modal')?.classList.toggle('active');
	}

	return (
		<>
			<div className="modal flex py-20 items-start justify-center">
				<div className="absolute top-5 right-5 cursor-pointer close--modal p-1 rounded-full hover:bg-zinc-100" onClick={handleCloseModal}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</div>

				<ul className="flex gap-20 modal--list items-center text-[13px] tracking-[3px]">
					{AppNavigation.map((x: NavigationItem, index: number) => {
						return (
							<li key={index}>
								<Link to={x.link}>{x.text}</Link>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="overlay" id="overlay--main--modal" onClick={handleCloseModal}></div>

			<header>
				<div
					className={`site--header flex justify-between py-4 pl-20 items-center ${
						noHeaderRoutes.some((x) => location.pathname.startsWith(x)) ? 'hidden' : ''
					}`}>
					<Link to="/">
						<img src={Logo} className="min-h-[50px] min-w-[168px] max-h-[50px] max-w-[168px]" />
					</Link>
					<ul id="header--list" className="flex gap-20 header--list items-center text-[13px] tracking-[3px]">
						{AppNavigation.map((x: NavigationItem, index: number) => {
							return (
								<li key={index}>
									<Link to={x.link}>{x.text}</Link>
								</li>
							);
						})}
					</ul>
					<div className="barToggler flex justify-center items-center cursor-pointer" onClick={toggleBar}>
						<div className="barToggler--button"></div>
					</div>
				</div>
			</header>
		</>
	);
}
