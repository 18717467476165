import React from 'react';

type Props = { children: React.ReactNode; className?: string };

export default function ComponentHolder({ children, className }: Props) {
	return (
		<div className={`flex flex-col gap-10 h-[73vh] overflow-y-auto leading-[34px] mastermind-component-holder pr-5 pb-32 ${className || ''}`}>
			{children}
		</div>
	);
}
