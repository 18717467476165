import React from 'react';
import EmbaUW from '../../../images/mastermind-plus/emba_uw.png';
import Emba from '../../../images/mastermind-plus/emba.png';
import WydzialZarzadzania from '../../../images/mastermind-plus/wydzial_zarzadzania.png';
import KawkaZDudkiem from '../../../images/mastermind-plus/kawka_z_dudkiem.png';
import MCZUW from '../../../images/mastermind-plus/mcz_uw.png';

type Props = {};

export default function PartnerzyProgramu({}: Props) {
	return (
		<div className="grid grid-cols-1 lg:grid-cols-2 mt-20 gap-y-20 justify-items-center w-fit mx-auto gap-x-40">
			<img src={WydzialZarzadzania} className="w-[600px] lg:col-span-2" alt="wydzial-zarzadzania" />
			<img src={EmbaUW} className="w-[300px]" alt="embauw" />
			<img src={MCZUW} className="w-[300px]" alt="mczuw" />
			<img src={Emba} className="w-[250px]" alt="emba" />
			<img src={KawkaZDudkiem} className="w-[200px]" alt="kawka-z-dudkiem" />
		</div>
	);
}
