import React from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
	text: string | undefined;
	h2Classes?: string;
	pageTitle?: string;
	specialImage?: any;
};

export default function MarkdownRenderer({ text, h2Classes, pageTitle, specialImage }: Props) {
	return (
		<div className={`flex flex-wrap mastermind-text-holder gap-10 max-h-[70vh]`}>
			<div className={`flex flex-col gap-10 h-full leading-[40px] w-full ${specialImage ? 'max-w-[800px]' : ''}`}>
				{pageTitle && <h1 className="text-2xl tracking-[1px]">{pageTitle}</h1>}
				<ReactMarkdown
					components={{
						em: (props) => {
							return <em className="tracking-[2px]">{props.children}</em>;
						},
						strong: (props) => {
							return <strong className="tracking-[2px] mastermind-plus-highlight">{props.children}</strong>;
						},
						h1: (props) => {
							return <h1 className="tracking-[2px] text-2xl font-medium uppercase">{props.children}</h1>;
						},
						h2: (props) => {
							return <h2 className={`tracking-[2px] text-xl font-medium uppercase contents ${h2Classes}`}>{props.children}</h2>;
						},
						h5: (props) => {
							return <h5 className={`tracking-[1px] text-sm font-medium contents ${h2Classes}`}>{props.children}</h5>;
						},
						p: (props) => {
							return <p className="tracking-[1px] font-medium">{props.children}</p>;
						},
						li: (props) => {
							return <li className="tracking-[1px] font-medium">{props.children}</li>;
						},
						a: (props) => {
							return (
								<a href={props.href} target="_blank" className="font-medium">
									{props.children}
								</a>
							);
						},
					}}>
					{text}
				</ReactMarkdown>
			</div>
			{specialImage && (
				<div className="flex flex-col gap-5">
					<img src={specialImage} className="h-min rounded-lg max-w-[360px]" />
					<span className="font-bold">
						Wydział Zarządzania <br /> Uniwersytetu Warszawskiego
					</span>
				</div>
			)}
		</div>
	);
}
