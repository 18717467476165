import React from 'react';
import { useLocation } from 'react-router-dom';
import PageFooter from './PageFooter';

type Props = {};

export default function Footer({}: Props) {
	const location = useLocation();

	let properFooter;
	const pathname = location.pathname;

	if (pathname.startsWith('/mastermind')) {
		properFooter = <></>;
	} else {
		properFooter = <PageFooter />;
	}

	return properFooter;
}
