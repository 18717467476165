import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MastermindPlusProps } from '../mastermind-texts';
import ReactMarkdown from 'react-markdown';
import LogoSvg from '../components/LogoSvg';
import MastermindIcon from '../../images/icons/MastermindIcon';
import { Collapse } from '@mui/material';
import PartnerzyProgramu from './MastermindPlusPages/PartnerzyProgramu';
import MarkdownRenderer from './MastermindPlusPages/MarkdownRenderer';
import ComponentHolder from './MastermindPlusPages/ComponentHolder';
import DlaKogo from './MastermindPlusPages/DlaKogo';
import EmbaUW from '../../images/mastermind-plus/emba_uw.png';
import Emba from '../../images/mastermind-plus/emba.png';
import WydzialZarzadzania from '../../images/mastermind-plus/wydzial_zarzadzania.png';
import KawkaZDudkiem from '../../images/mastermind-plus/kawka_z_dudkiem.png';
import MCZUW from '../../images/mastermind-plus/mcz_uw.png';
import Eksperci from './MastermindPlusPages/Eksperci';
import KalendarzZjazdow from './MastermindPlusPages/KalendarzZjazdow';
import CykleSpotkan from './MastermindPlusPages/CykleSpotkan';
import CertyfikatUkonczenia from './MastermindPlusPages/CertyfikatUkonczenia';
import KosztUczestnictwa from './MastermindPlusPages/KosztUczestnictwa';
import People from '../../images/mastermind_people.png';
import Wydzial from '../../images/mastermind-plus/wydzial.png';
import Zapraszamy from './MastermindPlusPages/Zapraszamy';

type Props = {};

export default function MastermindPlus({}: Props) {
	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		mainCircleHolder.current?.querySelectorAll<HTMLDivElement>('.circle').forEach((circle: HTMLDivElement) => {
			if (circle.id !== 'circle-' + id) {
				circle.classList.remove('active');
			}
		});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}

	const [insightsClicked, setInsightsClicked] = useState<boolean | null>(null);

	const [currentComponent, setCurrentComponent] = useState<React.JSX.Element>();

	const location = useLocation();
	const pathname = location.pathname;
	const navigate = useNavigate();

	const shouldDisplayFooterLogos: boolean = !pathname.includes('certyfikat') && !pathname.includes('zapraszamy') && pathname !== '/mastermind+';

	const mainCircleHolder = useRef<HTMLDivElement>(null);

	const [listExpanded, setListExpanded] = useState<boolean>(false);

	useEffect(() => {
		// to handle changing pathname using the next/back arrows - not by clicking the logo
		if (pathname === '/mastermind+') {
			setInsightsClicked(false);
			document.querySelector('.mastermind-plus-background')?.classList.remove('active');
			return;
		} else {
			setInsightsClicked(true);
		}

		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});

		if (pathname !== '/mastermind+') document.querySelector('.mastermind-plus-background')?.classList.add('active');

		if (pathname.includes('tematy')) {
			setCurrentComponent(
				<ComponentHolder className="">
					<CykleSpotkan />
				</ComponentHolder>
			);

			return;
		}

		switch (pathname) {
			case '/mastermind+/co-otrzymujesz': {
				setCurrentComponent(
					<MarkdownRenderer
						text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/co-otrzymujesz']}
						pageTitle="Co otrzymujesz w Mastermind+"
					/>
				);
				break;
			}
			case '/mastermind+/co-nas-wyroznia': {
				setCurrentComponent(
					<MarkdownRenderer
						text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/co-nas-wyroznia']}
						pageTitle="Co nas wyróżnia"
						specialImage={Wydzial}
					/>
				);
				break;
			}

			case '/mastermind+/partnerzy-programu': {
				setCurrentComponent(
					<ComponentHolder>
						<PartnerzyProgramu />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/dla-kogo': {
				setCurrentComponent(
					<ComponentHolder>
						<DlaKogo />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/top-korzysci': {
				setCurrentComponent(
					<MarkdownRenderer text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/top-korzysci']} pageTitle="Top korzyści dla Ciebie" />
				);
				break;
			}

			case '/mastermind+/dodatkowe-korzysci': {
				setCurrentComponent(
					<MarkdownRenderer
						text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/dodatkowe-korzysci']}
						pageTitle="Dodatkowo otrzymasz dostęp do:"
					/>
				);
				break;
			}

			case '/mastermind+/przymierze-umyslow': {
				setCurrentComponent(
					<MarkdownRenderer text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/przymierze-umyslow']} pageTitle="Przymierze umysłów" />
				);
				break;
			}

			case '/mastermind+/eksperci': {
				setCurrentComponent(
					<ComponentHolder className="!max-h-[65vh]">
						<Eksperci />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/kalendarz-zjazdow': {
				setCurrentComponent(
					<ComponentHolder>
						<KalendarzZjazdow />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/certyfikat-ukonczenia': {
				document.querySelector('.mastermind-plus-background')?.classList.remove('active');

				setCurrentComponent(
					<ComponentHolder>
						<CertyfikatUkonczenia />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/koszt-i-zapisy': {
				setCurrentComponent(
					<ComponentHolder>
						<KosztUczestnictwa />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/zapraszamy': {
				setCurrentComponent(
					<ComponentHolder>
						<Zapraszamy />
					</ComponentHolder>
				);
				break;
			}

			default: {
				navigate('/mastermind+/co-otrzymujesz');
				break;
			}
		}
	}, [pathname]);

	function handleInsightsClick() {
		if (!insightsClicked) {
			setTimeout(() => {
				document.querySelector('.mastermind-plus-background')?.classList.add('active');

				navigate(`co-otrzymujesz`);
			}, 400);
		} else {
			document.querySelector('.mastermind-plus-background')?.classList.remove('active');

			navigate(`/mastermind+`);
		}
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		if (pathname.length > '/mastermind+'.length) {
			if (!pathname.includes('certyfikat')) document.querySelector('.mastermind-plus-background')?.classList.add('active');

			setInsightsClicked(true);
		}
	}, []);

	useEffect(() => {
		if (insightsClicked === null) return;

		mainCircleHolder.current?.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
			setTimeout(() => {
				circle.classList.toggle('expanded', insightsClicked);
				circle.classList.toggle('shrinked', !insightsClicked);
				setTimeout(() => {
					circle.classList.remove('shrinked');
				}, 500);
			}, 20);
		});
		mainCircleHolder.current?.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});
		if (!insightsClicked) {
			document.getElementById('circle-1')?.classList.add('active');
		}

		document.querySelector('.mastermind-content-displayer')?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	const [isOpen, setIsOpen] = useState(false);

	function handleCloseModal() {
		setIsOpen(false);
		mastermindModalRef.current?.classList.remove('active');
		overlayRef.current?.classList.remove('active');
		const html = document.querySelector('html');
		if (html) html.style.overflowY = 'visible';
	}

	function toggleBar() {
		setIsOpen((prev) => !prev);
		mastermindModalRef.current?.classList.toggle('active');
		overlayRef.current?.classList.toggle('active');
		console.log(overlayRef.current?.classList);
	}

	const mastermindModalRef = useRef<HTMLDivElement>(null);
	const barTogglerRef = useRef<HTMLDivElement>(null);
	const overlayRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const html = document.querySelector('html');

		if (isOpen) {
			barTogglerRef.current?.classList.add('open');
			if (html) html.style.overflowY = 'hidden';
		} else {
			barTogglerRef.current?.classList.remove('open');
			if (html) html.style.overflowY = 'visible';
		}
	}, [isOpen]);

	return (
		<div className="mastermind-content mastermind-plus">
			<div id="overlay" onClick={handleCloseModal} ref={overlayRef}></div>

			<div className="mastermind-circles" ref={mainCircleHolder}>
				<div className={`mastermind-icon ${!insightsClicked ? 'block' : 'hidden'} `} onClick={handleInsightsClick}>
					<MastermindIcon />
				</div>
				<div className="relative">
					<div className="circle active" id="circle-1">
						<div className="!w-[600px] cursor-pointer" onClick={handleInsightsClick}>
							<p className="tracking-[2px] text-3xl leading-relaxed">
								Strategiczny MASTERMIND + <br /> dla liderek i liderów przyszłości
							</p>
							<p className="tracking-[2px] text-3xl leading-relaxed">
								Rozwijaj zyskowne biznesy <br />
								Redefiniuj swój styl przywództwa <br />
								Wyjdź poza samotność lidera <br />
								Pracuj z takimi, jak ty
							</p>
							<span className="text-sm">
								EDYCJA 2025
								<br />
								<br />
								<Link to="/">www.oceancapital.pl</Link>
								<br />
								e-mail: <a href="mailto:mastermind@oceancapital.pl">mastermind@oceancapital.pl</a>
							</span>
							<span className="font-bold w-fit">Przejdź</span>
						</div>
					</div>
					<div className="circle-small first " id="1" onClick={handleDotClick}></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-2"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-3"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-4"></div>
				</div>
			</div>

			<div className={`mastermind-plus-background z-[100]`}>
				{!MastermindPlusProps.noSmallCircleRoutes.includes(pathname) && (
					<div
						id="circle-small"
						style={{
							background: `conic-gradient(from 180deg at 50% 50%, rgba(217, 217, 217, 0) 0deg, ${MastermindPlusProps.masterMindPlusPageColors[pathname]}  360deg)`,
						}}></div>
				)}
				{!MastermindPlusProps.noBigCircleRoutes.includes(pathname) && (
					<div
						id="circle-big"
						style={{
							background: `conic-gradient(from 180deg at 50% 50%, ${MastermindPlusProps.masterMindPlusPageColors[pathname]}  0deg, rgba(217, 217, 217, 0) 360deg)`,
						}}></div>
				)}
			</div>

			<div className="modal flex py-20 items-start justify-center z-[200] overflow-y-auto" ref={mastermindModalRef}>
				<div className="absolute top-5 right-5 cursor-pointer close--modal p-1 rounded-full hover:bg-zinc-100" onClick={handleCloseModal}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</div>

				<ul className="flex gap-10 dotted-list in-place items-center text-[13px] tracking-[3px] ">
					{MastermindPlusProps.masterMindPlusTitles.map((item: MastermindPlusProps.MastermindSidebarItem, index: number) => {
						return item.children ? (
							<li className={`flex flex-col gap-3 children-holder relative`}>
								<div
									onClick={() => {
										setListExpanded(!listExpanded);
									}}>
									{item.value}
								</div>
								<Collapse in={listExpanded}>
									<ul className="flex flex-col gap-2 pl-5 dotted-list children">
										{item.children.map((title: MastermindPlusProps.MastermindSidebarItem, subItemIndex: number) => {
											return (
												<li
													key={subItemIndex}
													className={``}
													onClick={() => {
														handleCloseModal();
													}}>
													<Link to={title.link}>
														<div>{title.value}</div>
													</Link>
												</li>
											);
										})}
									</ul>
								</Collapse>
							</li>
						) : (
							<li
								className={``}
								onClick={() => {
									handleCloseModal();
									setListExpanded(false);
								}}>
								<Link to={item.link}>
									<div>{item.value}</div>
								</Link>
							</li>
						);
					})}
					<li>
						<div>
							<Link to="/">Strona główna OCEAN CAPITAL</Link>
						</div>
					</li>
				</ul>
			</div>
			{insightsClicked && (
				<div className="barToggler flex justify-center items-center cursor-pointer z-[400]" onClick={toggleBar} ref={barTogglerRef}>
					<div className="barToggler--button"></div>
				</div>
			)}
			<div className="mastermind-content-displayer text-white flex justify-between w-[85%]">
				<div className={`mastermind-icon outered ${insightsClicked ? 'block' : 'hidden'}`} onClick={handleInsightsClick}>
					<MastermindIcon />
				</div>

				<div className="flex flex-col dotted-list-holder">
					<ul className="max-h-[60vh] overflow-y-auto dotted-list pb-8 !gap-[20px]">
						{MastermindPlusProps.masterMindPlusTitles.map((item: MastermindPlusProps.MastermindSidebarItem, index: number) => {
							return item.children ? (
								<li className={`flex flex-col children-holder relative`}>
									<div
										onClick={() => {
											setListExpanded(!listExpanded);
										}}>
										{item.value}
									</div>
									<Collapse in={listExpanded}>
										<ul className="flex flex-col gap-2 pl-5 dotted-list children mt-3 !gap-[20px]">
											{item.children.map((title: MastermindPlusProps.MastermindSidebarItem, subItemIndex: number) => {
												return (
													<li
														key={subItemIndex}
														className={`${pathname === title.link ? 'active' : ''}`}
														onClick={() => {
															handleCloseModal();
														}}>
														<Link to={title.link} className="contents">
															<div>{title.value}</div>
														</Link>
													</li>
												);
											})}
										</ul>
									</Collapse>
								</li>
							) : (
								<li
									className={`${pathname === item.link ? 'active' : ''}`}
									onClick={() => {
										setListExpanded(false);
									}}>
									<Link to={item.link} className="contents">
										<div>{item.value}</div>
									</Link>
								</li>
							);
						})}
						<li>
							<div>
								<Link to="/">Strona główna OCEAN CAPITAL</Link>
							</div>
						</li>
					</ul>
					<div
						className={`xl:flex items-center gap-16 ${
							!shouldDisplayFooterLogos ? 'bg-transparent' : 'bg-black'
						} xl:fixed hidden w-[90%] right-0 bottom-0 pt-12 pb-14 px-10 justify-end h-[50px] z-[100]`}>
						<Link to="/" className="cursor-pointer w-min block mr-auto">
							<LogoSvg />
						</Link>
						{shouldDisplayFooterLogos && (
							<>
								<img src={WydzialZarzadzania} alt="wydzial-zarzadzania" className="w-[250px]" />
								<img src={EmbaUW} alt="embauw" className="w-[150px]" />
								<img src={MCZUW} alt="mczuw" className="w-[150px]" />
								<img src={Emba} alt="emba" className="w-[150px]" />
								<img src={KawkaZDudkiem} alt="kawka-z-dudkiem" className="w-[150px]" />
							</>
						)}
					</div>
				</div>
				{currentComponent}
			</div>
		</div>
	);
}
