import React from 'react';

type Props = {
	children: React.ReactNode;
	className?: string;
};

export default function MastermindTitle({ children, className }: Props) {
	return <h1 className={`lg:text-4xl text-3xl tracking-wider font-thin text-center ${className || ''}`}>{children}</h1>;
}
