import React from 'react';
import TopMenedzerowie from '../../../images/icons/TopMenedzerowie';
import CzlonkowieZarzadow from '../../../images/icons/CzlonkowieZarzadow';
import Wlasciciele from '../../../images/icons/Wlasciciele';
import Przedsiebiorcy from '../../../images/icons/Przedsiebiorcy';

type Props = {};

export default function DlaKogo({}: Props) {
	return (
		<div className="flex items-center justify-center mt-16 dla-kogo-icons flex-wrap">
			<div>
				<TopMenedzerowie />
			</div>
			<div>
				<CzlonkowieZarzadow />
			</div>
			<div>
				<Wlasciciele />
			</div>
			<div>
				<Przedsiebiorcy />
			</div>
		</div>
	);
}
