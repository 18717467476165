import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../images/logo.png';
import { MastermindPlusProps } from '../../mastermind-texts';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Collapse } from '@mui/material';

type Props = {
	insightsClicked: boolean | null;
};

export default function MastermindPlusFooter({ insightsClicked }: Props) {
	const [listExpanded, setListExpanded] = useState<boolean>(false);

	const location = useLocation();
	const pathname: string = location.pathname;
	const navigate = useNavigate();

	function handleCurrentPageClick() {
		document.querySelector('.footer--expander')?.classList.toggle('active');
		document.body.style.position = 'fixed';
	}

	function handleCloseFooter() {
		document.querySelector('.footer--expander')?.classList.remove('active');
	}

	return insightsClicked ? (
		<>
			<footer className="w-full text-black site--footer flex justify-center items-center mastermind-footer">
				<BsChevronCompactDown className="absolute left-6" size={30} onClick={handleCurrentPageClick} />
				<span className="uppercase font-semibold text-xs tracking-[2.5px] text-center px-5 max-w-[300px]" onClick={handleCurrentPageClick}>
					{MastermindPlusProps.masterMindPlusLinkValues[pathname]}
				</span>
			</footer>
			<div className="footer--expander flex flex-col mastermind-expander">
				<ul className="flex dotted-list in-place items-center tracking-[3px] my-10 !gap-[20px]">
					{MastermindPlusProps.masterMindPlusTitles.map((item: MastermindPlusProps.MastermindSidebarItem, index: number) => {
						return item.children ? (
							<li className={`flex flex-col children-holder relative`}>
								<div
									onClick={() => {
										setListExpanded(!listExpanded);
									}}>
									{item.value}
								</div>
								<Collapse in={listExpanded}>
									<ul className="flex flex-col pl-5 dotted-list children mt-3 !gap-[20px]">
										{item.children.map((title: MastermindPlusProps.MastermindSidebarItem, subItemIndex: number) => {
											return (
												<li
													key={subItemIndex}
													className={`${pathname === title.link ? 'active' : ''}`}
													onClick={() => {
														handleCloseFooter();
													}}>
													<Link to={title.link} className="contents">
														<div>{title.value}</div>
													</Link>
												</li>
											);
										})}
									</ul>
								</Collapse>
							</li>
						) : (
							<li
								className={`${pathname === item.link ? 'active' : ''}`}
								onClick={() => {
									handleCloseFooter();
									setListExpanded(false);
								}}>
								<Link to={item.link} className="contents">
									<div>{item.value}</div>
								</Link>
							</li>
						);
					})}
					<li>
						<div>
							<Link to="/">Strona główna OCEAN CAPITAL</Link>
						</div>
					</li>
				</ul>
			</div>
		</>
	) : (
		<></>
	);
}
