import React from 'react';
import People from '../../../images/mastermind_people.png';
import { Link } from 'react-router-dom';

type Props = {};

export default function Zapraszamy({}: Props) {
	return (
		<>
			<div className="fixed bottom-[6%] z-[200] w-[90%] text-xs flex flex-col justify-end">
				<div className="relative text-end tracking-[1px]">
					<div className="flex flex-col absolute right-[0] w-[320px] top-[-150px] font-semibold">
						<Link to="/mentoring/aleksandra-slifirska" className="contents">
							<span className="uppercase mt-6"> Aleksandra Ślifirska</span>
							<span className="">Mentorka</span>
							<span className="">OCEAN CAPITAL </span>
						</Link>
					</div>
					<div className="flex flex-col absolute right-[0] w-[320px] top-[-65px] font-semibold">
						<Link to="/mentoring/tomasz-dalach" className="contents">
							<span className="uppercase mt-6"> Tomasz Dalach</span>
							<span className="">Mentor</span>
							<span className="">OCEAN CAPITAL </span>
						</Link>
					</div>

					<div className="absolute opacity-60 bottom-[5%] left-[50%] translate-x-[-50%] tracking-[3px] font-medium whitespace-nowrap flex flex-col gap-2 items-center">
						<span>www.oceancapital.pl</span>
						<a href="mailto:mastermind@oceancapital.pl">e: mastermind@oceancapital.pl</a>
					</div>
					<img src={People} className="w-[280px] ml-[11%]" />
				</div>
			</div>
		</>
	);
}
