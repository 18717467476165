import React, { useEffect, useState } from 'react';
import { MastermindPlusProps } from '../../mastermind-texts';
import { useLocation } from 'react-router-dom';
import MarkdownRendererGlobal from '../../components/MarkdownRendererGlobal';

type Props = {};

export default function CykleSpotkan({}: Props) {
	const pathname: string = useLocation().pathname;

	const [cycleData, setCycleData] = useState<MastermindPlusProps.MastermindPlusMeetingCycle>();
	const [mainColor, setMainColor] = useState<string>('');

	useEffect(() => {
		setCycleData(
			MastermindPlusProps.MeetingCycleData.find(
				(x) => x.pageTitle.toLowerCase() === pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length).replaceAll('-', ' ')
			) as MastermindPlusProps.MastermindPlusMeetingCycle
		);

		setMainColor(MastermindPlusProps.masterMindPlusPageColors[pathname]);
	}, [pathname]);

	if (!cycleData) return <></>;

	return (
		<>
			<h1 className="text-4xl font-medium">{cycleData.pageTitle}</h1>
			<div className="flex flex-col overflow-y-auto max-h-[58vh] pr-5">
				<div className="flex flex-col cycle-content ">
					<div className="cycle-row">
						<span>korzyści</span>
						<div className="flex flex-col w-full">
							<MarkdownRendererGlobal text={cycleData.korzysci} pClasses="!font-normal !tracking-normal" />
						</div>
					</div>
					<div className="mastermind-plus-spacer relative">
						<div className="bg-white px-2 py-1 text-black font-semibold rounded-md w-fit absolute right-[-1px] top-1/2 translate-y-[-50%] text-xs">
							{cycleData.firstPart.duration}
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="cycle-row">
							<span className="relative">
								temat przewodni
								<div
									className={`px-4 py-1 text-white font-semibold rounded-md w-fit absolute right-[0] translate-x-[100%] top-[-30px] translate-y-[-50%] text-xs uppercase tracking-widest`}
									style={{
										backgroundColor: mainColor,
									}}>
									Część 1
								</div>
							</span>
							<p>{cycleData.firstPart.tematPrzewodni}</p>
						</div>
						<div className="cycle-row">
							<span>prowadzenie</span>
							<p>{cycleData.firstPart.prowadzenie}</p>
						</div>
					</div>
					<div className="mastermind-plus-spacer relative">
						<div className="bg-white px-2 py-1 text-black font-semibold rounded-md w-fit absolute right-[-1px] top-1/2 translate-y-[-50%] text-xs">
							{cycleData.secondPart.duration}
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="cycle-row">
							<span className="relative">
								temat przewodni
								<div
									className={`px-4 py-1 text-white font-semibold rounded-md w-fit absolute right-[5px] translate-x-[100%] top-[-30px] translate-y-[-50%] text-xs uppercase tracking-widest`}
									style={{
										backgroundColor: mainColor,
									}}>
									Część 2
								</div>
							</span>
							<p>{cycleData.secondPart.tematPrzewodni}</p>
						</div>
						<div className="cycle-row">
							<span>prowadzenie</span>
							<p>{cycleData.secondPart.prowadzenie}</p>
						</div>
					</div>
					<div className="mastermind-plus-spacer"></div>
					<div className="flex flex-col">
						<div className="cycle-row">
							<span>kiedy</span>
							<p>{cycleData.kiedy}</p>
						</div>
						<div className="cycle-row">
							<span>obszar</span>
							<p>{cycleData.obszar}</p>
						</div>
						<div className="cycle-row">
							<span>lokalizacja</span>
							<p>{cycleData.lokalizacja}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
