import React from 'react';
import MastermindTitle from '../../components/MastermindTitle';
import { Link } from 'react-router-dom';

type Props = {};

export default function KosztUczestnictwa({}: Props) {
	return (
		<div className="font-semibold">
			<MastermindTitle>Koszt uczestnictwa i zapisy</MastermindTitle>
			<div className="mastermind-costs-table">
				<table>
					<thead>
						<tr>
							<th></th>
							<th>"early bird" - cena netto przy płatności jednorazowej do 10 lutego 2025 / 1 os.</th>
							<th>cena netto przy płatności jednorazowej do 14 marca 2025 / 1 os.</th>
							<th>cena przy płatności dzielonej: 50% do 10 lutego 2025 i 50% do 14 marca 2025 / 1 os.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1 osoba</td>
							<td>15 000</td>
							<td>16 000</td>
							<td>16 500</td>
						</tr>
						<tr>
							<td>2-3 osoby z jednej firmy</td>
							<td>14 250</td>
							<td>15 200</td>
							<td>15 675</td>
						</tr>
						<tr>
							<td>4-16 osób z jednej firmy - wewnętrzny Mastermind+</td>
							<td colSpan={3}>prośba o kontakt - warunki ustalane indywidualnie</td>
						</tr>
					</tbody>
				</table>
			</div>
			<p className="">
				Jeśli chcesz porozmawiać o programie Mastermind+ lub dokonać wstępnej rezerwacji, prosimy o przesłanie e-maila na adres:{' '}
				<a href="mailto:mastermind@oceancapital.pl" className="border-b border-white">
					mastermind@oceancapital.pl
				</a>
				<br /> lub umówienie się na spotkanie informacyjne używając linku:{' '}
				<a href="https://oceancapital.pl/kontakt/umow-spotkanie" target="_blank" className="border-b border-white">
					https://oceancapital.pl/kontakt/umow-spotkanie
				</a>
			</p>
			<p className="mt-6">Rezerwacja miejsca następuje po opłaceniu programu.</p>
		</div>
	);
}
