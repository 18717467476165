import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MastermindPlusProps } from '../mastermind-texts';
import LogoSvg from '../components/LogoSvg';
import MastermindIcon from '../../images/icons/mastermind.svg';
import PartnerzyProgramu from './MastermindPlusPages/PartnerzyProgramu';
import MarkdownRenderer from './MastermindPlusPages/MarkdownRenderer';
import ComponentHolder from './MastermindPlusPages/ComponentHolder';
import DlaKogo from './MastermindPlusPages/DlaKogo';
import Eksperci from './MastermindPlusPages/Eksperci';
import KalendarzZjazdow from './MastermindPlusPages/KalendarzZjazdow';
import CertyfikatUkonczenia from './MastermindPlusPages/CertyfikatUkonczenia';
import KosztUczestnictwa from './MastermindPlusPages/KosztUczestnictwa';
import People from '../../images/mastermind_people.png';
import CykleSpotkan from './MastermindPlusPages/CykleSpotkan';
import Logo from '../../images/logo_white.png';
import MastermindPlusFooter from './Footers/MastermindPlusFooter';
import Zapraszamy from './MastermindPlusPages/Zapraszamy';
import Wydzial from '../../images/mastermind-plus/wydzial.png';

type Props = {};

export default function MastermindPlus({}: Props) {
	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		mainCircleHolder.current?.querySelectorAll<HTMLDivElement>('.circle').forEach((circle: HTMLDivElement) => {
			if (circle.id !== 'circle-' + id) {
				circle.classList.remove('active');
			}
		});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}
	const [insightsClicked, setInsightsClicked] = useState<boolean | null>(null);

	const [currentComponent, setCurrentComponent] = useState<React.JSX.Element>();

	const location = useLocation();
	const pathname = location.pathname;
	const navigate = useNavigate();

	const shouldDisplayFooterLogos: boolean = !pathname.includes('certyfikat') && !pathname.includes('zapraszamy') && pathname !== '/mastermind+';

	const mainCircleHolder = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// to handle changing pathname using the next/back arrows - not by clicking the logo
		if (pathname === '/mastermind+') {
			setInsightsClicked(false);
			document.querySelector('.mastermind-plus-background')?.classList.remove('active');
			return;
		} else {
			setInsightsClicked(true);
		}

		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});
		document.querySelector('.mastermind-component-holder')?.scrollTo({
			top: 0,
			left: 0,
		});

		if (pathname !== '/mastermind+') document.querySelector('.mastermind-plus-background')?.classList.add('active');

		if (pathname.includes('tematy')) {
			setCurrentComponent(
				<ComponentHolder>
					<CykleSpotkan />
				</ComponentHolder>
			);

			return;
		}
		switch (pathname) {
			case '/mastermind+/co-otrzymujesz': {
				setCurrentComponent(
					<MarkdownRenderer
						text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/co-otrzymujesz']}
						pageTitle={`Co otrzymujesz`}
						escapedWithNewLine="w Mastermind+"
					/>
				);
				break;
			}
			case '/mastermind+/co-nas-wyroznia': {
				setCurrentComponent(
					<MarkdownRenderer
						text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/co-nas-wyroznia']}
						pageTitle="Co nas wyróżnia"
						specialImage={Wydzial}
					/>
				);
				break;
			}

			case '/mastermind+/partnerzy-programu': {
				setCurrentComponent(
					<ComponentHolder>
						<PartnerzyProgramu />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/dla-kogo': {
				setCurrentComponent(
					<ComponentHolder>
						<DlaKogo />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/top-korzysci': {
				setCurrentComponent(
					<MarkdownRenderer text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/top-korzysci']} pageTitle="Top korzyści dla Ciebie" />
				);
				break;
			}

			case '/mastermind+/dodatkowe-korzysci': {
				setCurrentComponent(
					<MarkdownRenderer
						text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/dodatkowe-korzysci']}
						pageTitle="Dodatkowo otrzymasz dostęp do:"
					/>
				);
				break;
			}

			case '/mastermind+/przymierze-umyslow': {
				setCurrentComponent(
					<MarkdownRenderer text={MastermindPlusProps.mastermindPlusTexts['/mastermind+/przymierze-umyslow']} pageTitle="Przymierze umysłów" />
				);
				break;
			}

			case '/mastermind+/eksperci': {
				setCurrentComponent(
					<ComponentHolder>
						<Eksperci />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/kalendarz-zjazdow': {
				setCurrentComponent(
					<ComponentHolder>
						<KalendarzZjazdow />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/certyfikat-ukonczenia': {
				document.querySelector('.mastermind-plus-background')?.classList.remove('active');

				setCurrentComponent(
					<ComponentHolder>
						<CertyfikatUkonczenia />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/koszt-i-zapisy': {
				setCurrentComponent(
					<ComponentHolder>
						<KosztUczestnictwa />
					</ComponentHolder>
				);
				break;
			}

			case '/mastermind+/zapraszamy': {
				document.querySelector('.mastermind-plus-background')?.classList.remove('active');

				setCurrentComponent(
					<ComponentHolder>
						<Zapraszamy />
					</ComponentHolder>
				);
				break;
			}

			default: {
				navigate('/mastermind+/co-otrzymujesz');
				break;
			}
		}
	}, [pathname]);

	function handleInsightsClick() {
		console.log('clicking, prev state was:', insightsClicked);
		if (!insightsClicked) {
			setTimeout(() => {
				document.querySelector('.mastermind-plus-background')?.classList.add('active');

				navigate(`co-otrzymujesz`);
			}, 400);
		} else {
			document.querySelector('.mastermind-plus-background')?.classList.remove('active');

			navigate(`/mastermind+`);
		}
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		document.querySelector<HTMLDivElement>('.site--content')?.style.setProperty('--actualViewportHeight', `${window.innerHeight}px`);

		if (pathname.length > '/mastermind+'.length) {
			if (!pathname.includes('certyfikat') && !pathname.includes('zapraszamy'))
				document.querySelector('.mastermind-plus-background')?.classList.add('active');

			setInsightsClicked(true);
		}
	}, []);

	useEffect(() => {
		if (insightsClicked === null) return;

		mainCircleHolder.current?.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
			setTimeout(() => {
				circle.classList.toggle('expanded', insightsClicked);
				circle.classList.toggle('shrinked', !insightsClicked);
				setTimeout(() => {
					circle.classList.remove('shrinked');
				}, 500);
			}, 20);
		});
		mainCircleHolder.current?.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});
		if (!insightsClicked) {
			document.getElementById('circle-1')?.classList.add('active');
		}

		document.querySelector('.mastermind-content-displayer')?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	return (
		<div className="mastermind-content site--content mastermind-plus">
			<div className="mastermind-icon !top-4" onClick={handleInsightsClick}>
				<div className="relative">
					<img src={MastermindIcon} className="w-[70px]" />
					<div className="circle-small bottom-[-14px] right-[-20px] opacity-50 forth"></div>
				</div>
			</div>
			{!insightsClicked && (
				<Link to="/" className="cursor-pointer absolute z-[300] bottom-6 left-4">
					<img src={Logo} className="w-[100px]" />
				</Link>
			)}

			<div className="mastermind-circles" ref={mainCircleHolder}>
				<div className="relative">
					<div className="circle active" id="circle-1">
						<div className="!w-[300px]" onClick={handleInsightsClick}>
							<p className="tracking-[1px] text-lg leading-relaxed font-semibold">
								Strategiczny MASTERMIND+ <br /> dla liderek i liderów przyszłości
							</p>
							<p className="tracking-[1px] leading-relaxed font-semibold">
								Rozwijaj zyskowne biznesy <br />
								Redefiniuj swój styl przywództwa <br />
								Wyjdź poza samotność lidera <br />
								Pracuj z takimi, jak ty
							</p>
							<span className="text-xs">
								EDYCJA 2025
								<br />
								<br />
								<Link to="www.oceancapital.pl">www.oceancapital.pl</Link>
								<br />
								e-mail: <a href="mailto:mastermind@oceancapital.pl">mastermind@oceancapital.pl</a>
							</span>
							<span className="cursor-pointer font-bold w-fit">Przejdź</span>
						</div>
					</div>
					<div className="circle-small first " id="1" onClick={handleDotClick}></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-2">
						<div>
							<span className="font-bold mt-6"> Tomasz Dalach</span>
							<span className="">Mentor prowadzący</span>
							<span className="opacity-60">MASTERMIND OCEAN CAPITAL </span>
							<Link to="/mentoring/tomasz-dalach">
								<i className="text-sm opacity-60">Link</i>
							</Link>
						</div>
						<div>
							<span className="font-bold mt-6"> Aleksandra Ślifirska</span>
							<span className="">Mentorka wspierająca</span>
							<span className="opacity-60">MASTERMIND OCEAN CAPITAL </span>
							<Link to="/mentoring/aleksandra-slifirska">
								<i className="text-sm opacity-60">Link</i>
							</Link>
						</div>
						<img src={People} className="w-[482px]" />
					</div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-3"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-4"></div>
				</div>
			</div>

			<div className={`mastermind-plus-background z-[100]`}>
				{!MastermindPlusProps.noSmallCircleRoutes.includes(pathname) && (
					<div
						id="circle-small"
						style={{
							background: `conic-gradient(from 180deg at 50% 50%, rgba(217, 217, 217, 0) 0deg, ${MastermindPlusProps.masterMindPlusPageColors[pathname]}  360deg)`,
						}}></div>
				)}
				{!MastermindPlusProps.noBigCircleRoutes.includes(pathname) && (
					<div
						id="circle-big"
						style={{
							background: `conic-gradient(from 180deg at 50% 50%, ${MastermindPlusProps.masterMindPlusPageColors[pathname]}  0deg, rgba(217, 217, 217, 0) 360deg)`,
						}}></div>
				)}
			</div>

			<div className="mastermind-content-displayer text-white flex justify-between w-[85%]">{currentComponent}</div>
			<MastermindPlusFooter insightsClicked={insightsClicked} />
		</div>
	);
}
