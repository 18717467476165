import React from 'react';
import Certyfikat from '../../../images/mastermind-plus/certyfikat.png';
import MastermindTitle from '../../components/MastermindTitle';

type Props = {};

export default function CertyfikatUkonczenia({}: Props) {
	return (
		<div className=" flex flex-col gap-10">
			<MastermindTitle>Certyfikat ukończenia programu Mastermind+</MastermindTitle>
			<div className="flex justify-center flex-wrap gap-20">
				<span className="max-w-[500px] tracking-[1px] leading-10 text-center">
					OCEAN CAPITAL potwierdza ukończenie programu rozwoju kompetencji liderskich, prowadzonego w procesie grupowym. Jego celem jest rozwój
					liderek i liderów oraz ich biznesów w holistycznym połączeniu eksperckich wykładów, grupowych sesji Mastermind i indywidualnego
					mentoringu.
				</span>
				<img src={Certyfikat} className="rounded-lg overflow-hidden" />
			</div>
		</div>
	);
}
