import React from 'react';
import Certyfikat from '../../../images/mastermind-plus/certyfikat.png';
import MastermindTitle from '../../components/MastermindTitle';

type Props = {};

export default function CertyfikatUkonczenia({}: Props) {
	return (
		<div className="flex flex-wrap gap-20 mb-32">
			<div className="flex flex-col gap-24 items-start">
				<MastermindTitle className="text-start">
					Certyfikat ukończenia <br />
					programu Mastermind+
				</MastermindTitle>
				<span className="max-w-[500px] tracking-[1px] leading-10">
					OCEAN CAPITAL potwierdza ukończenie programu rozwoju kompetencji liderskich, prowadzonego w procesie grupowym. Jego celem jest rozwój
					liderek i liderów oraz ich biznesów w holistycznym połączeniu eksperckich wykładów, grupowych sesji Mastermind i indywidualnego
					mentoringu.
				</span>
			</div>
			<img src={Certyfikat} className="rounded-lg overflow-hidden" width={500} />
		</div>
	);
}
