import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Grupowe from '../../../images/grupowe1.jpg';
import Logo from '../../../images/logo.png';
import { BsChevronCompactDown } from 'react-icons/bs';
import { AppNavigation, NavigationItem } from '../../globalTypes';

type Props = {};

export default function PageFooter({}: Props) {
	const location = useLocation();

	const [currentPageText, setCurrentPageText] = useState<string>('wartości');

	useEffect(() => {
		const paths: string[] = ['/', '/mentoring', '/mastermind', '/dla-kogo', '/na-miare', '/referencje', '/kontakt']; //these need to be in the same order as they are placed on the page (from top to bottom)
		const pathname: string = location.pathname;
		let nested: boolean = false;
		if (pathname.slice(1).indexOf('/') !== -1) {
			nested = true;
		}
		const path: string = !nested ? pathname : '/' + pathname.slice(1).substring(0, pathname.slice(1).indexOf('/'));

		document.querySelector<HTMLDivElement>('.site--content')?.style.setProperty('--actualViewportHeight', `${window.innerHeight}px`);

		if (path === '/polityka-prywatnosci') {
			setCurrentPageText('Polityka prywatności');
			return;
		}

		const headerItems: NodeListOf<HTMLLIElement> = document.querySelectorAll<HTMLLIElement>('.footer--list>li');

		headerItems.forEach((li: HTMLLIElement) => li.classList.remove('active'));
		if (paths.indexOf(path) !== -1) headerItems[paths.indexOf(path)].classList.add('active');

		const properIndex = paths.indexOf(path) === -1 ? 1 : paths.indexOf(path);
		const currentPageText: string = document.querySelectorAll<HTMLLinkElement>('.footer--list>li>a')[properIndex].textContent || '';
		setCurrentPageText(currentPageText);
	}, [location]);

	useEffect(() => {
		document.querySelectorAll<HTMLLinkElement>('.footer--list>li>a').forEach((a: HTMLLinkElement) => {
			a.addEventListener('click', () => {
				document.querySelector('.footer--expander')?.classList.remove('active');
				setCurrentPageText(a.textContent || '');
				document.body.style.position = 'relative';
			});
		});
	}, []);

	function handleCurrentPageClick() {
		document.querySelector('.footer--expander')?.classList.toggle('active');
		document.body.style.position = 'fixed';
	}

	useEffect(() => {
		document.querySelector('.footer--expander')?.classList.remove('active');
	}, [location]);

	return (
		<>
			<footer className="w-full text-black site--footer flex justify-center items-center">
				{!location.pathname.match(/mentoring\/.+/g) && (
					<BsChevronCompactDown className="absolute left-6" size={30} onClick={handleCurrentPageClick} />
				)}
				<span className="uppercase font-semibold text-xs tracking-[2.5px]" onClick={handleCurrentPageClick}>
					{currentPageText}
				</span>
			</footer>
			<div className="footer--expander flex flex-col">
				<div className="site--header flex justify-between py-8 pl-5 items-center pr-[5%]">
					<Link to="/">
						<img src={Logo} className="max-h-[50px] w-auto" />
					</Link>
				</div>
				<img src={Grupowe} className="h-[300px] mx-auto" />
				<ul className="flex flex-col footer--list justify-between items-center my-auto gap-3 text-[14px] tracking-[3px] uppercase">
					{AppNavigation.map((x: NavigationItem, index: number) => {
						return (
							<li key={index}>
								<Link to={x.link}>{x.text}</Link>
							</li>
						);
					})}
				</ul>
			</div>
		</>
	);
}
