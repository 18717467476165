import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import People from '../../images/mastermind_people.png';
import { MastermindSidebarTitle, mastermindTexts, mastermindTitles } from '../mastermind-texts';
import LogoSvg from '../components/LogoSvg';
import MarkdownRenderer from './MastermindPlusPages/MarkdownRenderer';
import MastermindIcon from '../../images/icons/MastermindIcon';

type Props = {};

export default function Mastermind({}: Props) {
	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		document.querySelectorAll<HTMLDivElement>('.mastermind-circles .circle').forEach((circle: HTMLDivElement) => {
			if (circle.id !== 'circle-' + id) {
				circle.classList.remove('active');
			}
		});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}

	const [insightsClicked, setInsightsClicked] = useState<boolean>(false);

	const location = useLocation();
	const pathname = location.pathname;
	const navigate = useNavigate();

	useEffect(() => {
		// to handle changing pathname using the next/back arrows - not by clicking the logo
		if (pathname === '/mastermind') {
			setInsightsClicked(false);
		} else {
			setInsightsClicked(true);
		}

		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});
	}, [pathname]);

	function handleInsightsClick() {
		if (!insightsClicked) {
			setTimeout(() => {
				document.querySelector('.mastermind-content-displayer')?.classList.add('active');

				navigate(`przymierze-umyslow`);
			}, 400);
		} else {
			document.querySelector('.mastermind-content-displayer')?.classList.remove('active');

			navigate(`/mastermind`);
		}
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		document.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
			setTimeout(() => {
				circle.classList.toggle('expanded', insightsClicked);
				circle.classList.toggle('shrinked', !insightsClicked);
				setTimeout(() => {
					circle.classList.remove('shrinked');
				}, 500);
			}, 20);
		});
		document.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});

		document.querySelector('.mastermind-content-displayer')?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	useEffect(() => {
		if (pathname.length > '/mastermind+'.length) {
			document.querySelector('.mastermind-content-displayer')?.classList.add('active');

			setInsightsClicked(true);
		}
	}, []);

	const [isOpen, setIsOpen] = useState(false);

	function handleCloseModal() {
		setIsOpen(false);
		mastermindModalRef.current?.classList.remove('active');
		overlayRef.current?.classList.remove('active');
		const html = document.querySelector('html');
		if (html) html.style.overflowY = 'visible';
	}

	function toggleBar() {
		setIsOpen((prev) => !prev);
		mastermindModalRef.current?.classList.toggle('active');
		overlayRef.current?.classList.toggle('active');
		console.log(overlayRef.current?.classList);
	}

	const mastermindModalRef = useRef<HTMLDivElement>(null);
	const barTogglerRef = useRef<HTMLDivElement>(null);
	const overlayRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const html = document.querySelector('html');

		if (isOpen) {
			barTogglerRef.current?.classList.add('open');
			if (html) html.style.overflowY = 'hidden';
		} else {
			barTogglerRef.current?.classList.remove('open');
			if (html) html.style.overflowY = 'visible';
		}
	}, [isOpen]);

	return (
		<div className="mastermind-content">
			<div id="overlay" onClick={handleCloseModal} ref={overlayRef}></div>

			<div className="mastermind-circles">
				<div className={`mastermind-icon ${!insightsClicked ? 'block' : 'hidden'} `} onClick={handleInsightsClick}>
					<MastermindIcon />
				</div>
				<div className="relative">
					<div className="circle" id="circle-1">
						<div>
							<i className="tracking-[2px]">
								„Umysł ludzki można porównać do baterii elektrycznej. Ogólnie wiadomo, że zespół baterii elektrycznych wytwarza więcej energii
								od baterii pojedynczej.
							</i>
							<span className="font-bold opacity-50">Napoleon Hill</span>
						</div>
					</div>
					<div className="circle-small first" id="1" onClick={handleDotClick}></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-2">
						<div>
							<span className="font-bold mt-6"> Tomasz Dalach</span>
							<span className="">Mentor prowadzący</span>
							<span className="opacity-60">MASTERMIND OCEAN CAPITAL </span>
							<Link to="/mentoring/tomasz-dalach">
								<i className="text-sm opacity-60">Link</i>
							</Link>
						</div>
						<div>
							<span className="font-bold mt-6"> Aleksandra Ślifirska</span>
							<span className="">Mentorka wspierająca</span>
							<span className="opacity-60">MASTERMIND OCEAN CAPITAL </span>
							<Link to="/mentoring/aleksandra-slifirska">
								<i className="text-sm opacity-60">Link</i>
							</Link>
						</div>
						<img src={People} className="w-[482px]" />
					</div>
					<div className="circle-small second" id="2" onClick={handleDotClick}></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-3">
						<div>
							<i>
								„Żaden umysł nie jest kompletny sam w sobie. Każdy potrzebuje połączenia i kontaktu z innymi umysłami, aby wzrastać i
								rozszerzać swoją świadomość.”
							</i>
							<span className="font-bold opacity-50">Napoleon Hill</span>
						</div>
					</div>
					<div className="circle-small third" id="3" onClick={handleDotClick}></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-4"></div>
				</div>
			</div>

			<div className="modal flex py-20 items-start justify-center" ref={mastermindModalRef}>
				<div className="absolute top-5 right-5 cursor-pointer close--modal p-1 rounded-full hover:bg-zinc-100" onClick={handleCloseModal}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</div>

				<ul className="flex gap-20 dotted-list in-place items-center text-[13px] tracking-[3px]">
					{mastermindTitles.map((title: MastermindSidebarTitle, index: number) => {
						return (
							<li
								className={`${title.link === pathname ? 'active' : ''}`}
								onClick={() => {
									handleCloseModal();
								}}>
								<Link to={title.link}>
									<div>{title.value}</div>
								</Link>
							</li>
						);
					})}
					<li>
						<Link to="/">
							<div>Strona główna OCEAN CAPITAL</div>
						</Link>
					</li>
				</ul>
			</div>
			{insightsClicked && (
				<div className="barToggler flex justify-center items-center cursor-pointer z-[400]" onClick={toggleBar} ref={barTogglerRef}>
					<div className="barToggler--button"></div>
				</div>
			)}
			<div className="mastermind-content-displayer text-white flex justify-between w-[85%]">
				<div className={`mastermind-icon outered ${insightsClicked ? 'block' : 'hidden'}`} onClick={handleInsightsClick}>
					<MastermindIcon />
				</div>

				<div className="flex flex-col dotted-list-holder">
					<ul className="max-h-[60vh] overflow-y-auto dotted-list pb-8">
						{mastermindTitles.map((title: MastermindSidebarTitle, index: number) => {
							return (
								<li key={index} className={`${title.link === pathname ? 'active' : ''}`}>
									<Link to={title.link}>
										<div>{title.value}</div>
									</Link>
								</li>
							);
						})}
						<li>
							<Link to="/">
								<div>Strona główna OCEAN CAPITAL</div>
							</Link>
						</li>
					</ul>
					<div className="ml-[50px] w-fit py-[25px] ocean-logo mt-[12%]">
						<Link to="/" className="cursor-pointer w-min block">
							<LogoSvg />
						</Link>
					</div>
				</div>
				<MarkdownRenderer text={mastermindTexts[pathname]} />
			</div>
		</div>
	);
}
