import React from 'react';
import People from '../../../images/mastermind_people.png';
import { Link } from 'react-router-dom';

type Props = {};

export default function Zapraszamy({}: Props) {
	return (
		<>
			<div className="mastermind-circles">
				<div className="relative">
					<div className="circle" id="circle-1"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-2"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-3"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-4"></div>
				</div>
			</div>

			<div className="fixed bottom-[17vh] left-[40vw] z-[200]">
				<div className="relative">
					<div className="flex flex-col absolute left-[-350px] 2xl:right-[-350px] 2xl:left-[unset] w-[320px] bottom-[230px]">
						<span className="uppercase mt-6"> Aleksandra Ślifirska</span>
						<span className="">Mentorka</span>
						<span className="">OCEAN CAPITAL </span>
						<Link to="/mentoring/aleksandra-slifirska">
							<i className="text-sm opacity-60">oceancapital.pl/mentoring/aleksandra-slifirska</i>
						</Link>
					</div>
					<div className="flex flex-col absolute left-[-350px] 2xl:right-[-350px] 2xl:left-[unset] w-[320px] bottom-[70px]">
						<span className="uppercase mt-6"> Tomasz Dalach</span>
						<span className="">Mentor</span>
						<span className="">OCEAN CAPITAL </span>
						<Link to="/mentoring/tomasz-dalach">
							<i className="text-sm opacity-60">oceancapital.pl/mentoring/tomasz-dalach</i>
						</Link>
					</div>

					<div className="absolute opacity-60 bottom-[-12px] tracking-[3px] flex gap-10 text-xl font-medium whitespace-nowrap">
						<span>www.oceancapital.pl</span>
						<a href="mailto:mastermind@oceancapital.pl">e: mastermind@oceancapital.pl</a>
					</div>
					<img src={People} className="min-w-[500px] max-w-[500px]" />
				</div>
			</div>
		</>
	);
}
