import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

type Props = {};

export default function NotFound({}: Props) {
	useEffect(() => {}, []);
	return (
		<div className="flex flex-col w-2/3 gap-32 mx-auto mt-32">
			<h1 className="text-5xl font-light tracking-[5px] uppercase">Error 404 - Nie znaleziono</h1>
			<p className="return--home">
				<Link to="/">Wróć na stronę główną</Link>
			</p>
		</div>
	);
}
